// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-3 {
    width: 100%; 
    height: 100%;
    margin: 10px; 
    background-color: #d4bba9;
    border-radius: 15px;
    overflow: hidden;
}

.scrollable {
    overflow: auto;
    height: 100%;
    width: 100%; 
    border: 0.5px solid #ddd;
    border-radius: 15px;
}

.kpclients-table {
    border-collapse: collapse;
    width: 100%;
}

.kpclients-table th, .kpclients-table td {
    border: 0.5px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Ensure text doesn't overflow the cell */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.kpclients-table th {
    background-color: #d4bba9;
    color: white;
}

.selected-cell {
    background-color: #d0e7ff;
}

.cell {
    -webkit-user-select: none;
            user-select: none;
    background-color: white;
}

.editable-cell {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
}

.editable-cell:focus {
    border: 1px solid #00f;
}

.buttonTable {
    margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Elements/Tables/kp clients/Kp_clients.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB,EAAE,+BAA+B;IACpD,gBAAgB,EAAE,0CAA0C;IAC5D,uBAAuB,EAAE,sCAAsC;AACnE;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".object-3 {\n    width: 100%; \n    height: 100%;\n    margin: 10px; \n    background-color: #d4bba9;\n    border-radius: 15px;\n    overflow: hidden;\n}\n\n.scrollable {\n    overflow: auto;\n    height: 100%;\n    width: 100%; \n    border: 0.5px solid #ddd;\n    border-radius: 15px;\n}\n\n.kpclients-table {\n    border-collapse: collapse;\n    width: 100%;\n}\n\n.kpclients-table th, .kpclients-table td {\n    border: 0.5px solid #ddd;\n    padding: 8px;\n    text-align: left;\n    white-space: nowrap; /* Prevent text from wrapping */\n    overflow: hidden; /* Ensure text doesn't overflow the cell */\n    text-overflow: ellipsis; /* Add ellipsis for overflowing text */\n}\n\n.kpclients-table th {\n    background-color: #d4bba9;\n    color: white;\n}\n\n.selected-cell {\n    background-color: #d0e7ff;\n}\n\n.cell {\n    user-select: none;\n    background-color: white;\n}\n\n.editable-cell {\n    width: 100%;\n    border: none;\n    background: transparent;\n    outline: none;\n}\n\n.editable-cell:focus {\n    border: 1px solid #00f;\n}\n\n.buttonTable {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
