// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-table {
    width: 100%; 
    display: flex;
    flex-direction: row;
}

.object-3 {
    width: 100%; 
    display: flex;
    flex-direction: row;
    background-color: #d4bba9;
    border-radius: 15px;
}

.scrollable {
    overflow: auto;
    max-height: 100%;
    max-width: 100%;
    border: 0.5px solid #ddd;
    border-radius: 15px;
    padding: 0; /* Remove padding */
}

.podryad-table {
    border-collapse: collapse;
    width: 100%; /* Set width to 100% */
    table-layout: auto;
}

.podryad-table th, .podryad-table td {
    border: 0.5px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
}

.podryad-table th {
    background-color: #d4bba9;
    color: white;

}

.selected-cell {
    background-color: #d0e7ff;
}

.cell {
    -webkit-user-select: none;
            user-select: none;
    background-color: white;
}

.editable-cell {
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
}

.editable-cell:focus {
    border: 1px solid #00f;
}

.buttonTable {
    margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Elements/Tables/prodachi/Prodazhi.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,wBAAwB;IACxB,mBAAmB;IACnB,UAAU,EAAE,mBAAmB;AACnC;;AAEA;IACI,yBAAyB;IACzB,WAAW,EAAE,sBAAsB;IACnC,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;;AAEhB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".object-table {\n    width: 100%; \n    display: flex;\n    flex-direction: row;\n}\n\n.object-3 {\n    width: 100%; \n    display: flex;\n    flex-direction: row;\n    background-color: #d4bba9;\n    border-radius: 15px;\n}\n\n.scrollable {\n    overflow: auto;\n    max-height: 100%;\n    max-width: 100%;\n    border: 0.5px solid #ddd;\n    border-radius: 15px;\n    padding: 0; /* Remove padding */\n}\n\n.podryad-table {\n    border-collapse: collapse;\n    width: 100%; /* Set width to 100% */\n    table-layout: auto;\n}\n\n.podryad-table th, .podryad-table td {\n    border: 0.5px solid #ddd;\n    padding: 8px;\n    text-align: left;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n.podryad-table th {\n    background-color: #d4bba9;\n    color: white;\n\n}\n\n.selected-cell {\n    background-color: #d0e7ff;\n}\n\n.cell {\n    user-select: none;\n    background-color: white;\n}\n\n.editable-cell {\n    width: 100%;\n    border: none;\n    background: transparent;\n    outline: none;\n}\n\n.editable-cell:focus {\n    border: 1px solid #00f;\n}\n\n.buttonTable {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
