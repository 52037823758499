import './Repository.css';

export default function Repos() {
    return(
        <div className='object-table'>
            <div className='object-3'>
                <div className='scrollable'>
            </div>
        </div>
    </div>
    )
}